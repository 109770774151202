/* eslint-disable no-unused-vars */
// @ts-nocheck
export const rowLinesTableLayout = {
  hLineWidth(i, node) {
    return i === 1 || i === node.table.body.length ? 1 : 0.5;
  },
  vLineWidth() {
    return 0;
  },
  hLineColor(i, node) {
    return i === 0 || i === 1 || i === node.table.body.length
      ? '#777'
      : 'lightgray';
  },
};

export const indentGuideLayout = {};
