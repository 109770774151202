import { useContext, useEffect } from 'react';
import ContentBlock from 'components/shared/ContentBlock';
import ProjectApiList from 'components/ProjectApiList';
import { useParams } from 'react-router-dom';

import { namespaceConstants } from 'constants/index';
import AppContainer from 'containers/AppLayout/AppContainer';
import { namespaceHelper, stringHelper } from 'helpers';
import { StoreContext } from 'contexts';
import ProjectCard from 'components/ProjectApiList/ProjectCard';
import { projectHooks } from 'hooks';
import ensureProjectsLoaded from 'containers/shared/ensureProjectsLoaded';

const { mapProjectPathToCode } = stringHelper;
const { changeCurrentNamespace } = namespaceHelper;

const ApiList = () => {
  const { projects, namespace } = useContext(StoreContext);

  const { projectPath, namespace: pathNamespace } = useParams<any>();

  const project = projects.find(
    p => p.code === mapProjectPathToCode(projectPath)
  );

  const {
    versions,
    fetchVersions,
    setParams,
    params,
    loading,
    pagination,
  } = projectHooks.useVersions({
    queryParams: {
      projectCode: project?.code ?? '',
      page: 1,
      pageSize: 10,
      namespace: namespace.code,
    },
    startFetching: !!project,
  });

  useEffect(() => {
    if (namespace.urlPath !== pathNamespace) {
      if (
        namespaceConstants.NAMESPACE_LIST.map(np => np.urlPath).includes(
          pathNamespace
        )
      ) {
        changeCurrentNamespace(String(pathNamespace).toUpperCase());
      }
      window.location.reload();
    }
  }, []);

  return (
    <AppContainer
      title={project?.path.split('/')[project?.path.split('/').length - 1]}
    >
      <ContentBlock>
        <ProjectCard
          project={project}
          params={params}
          setParams={setParams}
        ></ProjectCard>
      </ContentBlock>
      <ContentBlock>
        <ProjectApiList
          project={project}
          fetchVersions={fetchVersions}
          loading={loading}
          pagination={pagination}
          params={params}
          setParams={setParams}
          versions={versions}
        />
      </ContentBlock>
    </AppContainer>
  );
};

export default ensureProjectsLoaded(ApiList);
