import createPdf from './pdf-gen';
import { localize } from './localize';
export interface IGeneratePdfOption {
  pdfSortTags?: boolean;
  pdfPrimaryColor?: string;
  pdfAlternateColor?: string;
  pdfTitle?: string;
  pdfCoverText?: string;
  pdfSecurityText?: string;
  pdfApiText?: string;
  pdfFooterText?: string;
  includeInfo?: boolean;
  includeToc?: boolean;
  includeSecurity?: boolean;
  includeExample?: boolean;
  includeApiDetails?: boolean;
  includeApiList?: boolean;
}

export default {
  generatePdf: (
    jsonObj: Object,
    options: IGeneratePdfOption = {
      pdfSortTags: false,
      pdfPrimaryColor: '',
      pdfAlternateColor: '',
      pdfTitle: 'API Reference',
      pdfCoverText: '',
      pdfSecurityText: '',
      pdfApiText: '',
      pdfFooterText: '',
      includeInfo: true,
      includeToc: true,
      includeSecurity: true,
      includeExample: true,
      includeApiDetails: true,
      includeApiList: false,
    }
  ) => {
    const pdfOptions = {
      localize,
      pdfSchemaStyle: 'object',
      ...options,
    };
    const spec = jsonObj;
    return createPdf(spec, pdfOptions);
  },
};
