import { TablePaginationConfig } from 'antd/es/table';
import { IProject } from 'interfaces';
import ProjectTable from './ProjectTable';
import { IGetVersionsParams, IVersion } from 'interfaces/project';
export interface IProjectApiListProp {
  project?: IProject;
  loading: boolean;
  setParams: React.Dispatch<React.SetStateAction<IGetVersionsParams>>;
  params: IGetVersionsParams;
  versions: IVersion[];
  fetchVersions: () => Promise<void>;
  pagination: false | TablePaginationConfig | undefined;
}

const ProjectApiList = ({
  project,
  loading,
  params,
  setParams,
  versions,
  fetchVersions,
  pagination,
}: IProjectApiListProp) => {
  const handleTableChange = ({ current, pageSize }: TablePaginationConfig) => {
    setParams({ ...params, page: current, pageSize: pageSize });
  };

  return (
    <div className="pb-base">
      {project && (
        <ProjectTable
          loading={loading}
          setParams={setParams}
          project={project}
          versions={versions}
          fetchVersions={fetchVersions}
          pagination={pagination}
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export default ProjectApiList;
