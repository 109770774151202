import { requestServices } from 'services';
import { requestHelpers } from 'helpers';
import {
  ICreateDeploymentConfigBody,
  IGetDeploymentConfigParams,
  IGetDeploymentConfigResponse,
  IGetMockUrlParams,
  IDeleteDeploymentConfigParams,
  IUpdateDeploymentConfigBody,
} from 'interfaces/deploymentConfig';
import { IApiResponse } from 'interfaces';
import { IGetMockUrlResponse } from '../../interfaces/deploymentConfig';

const { docBuilderClient } = requestServices;

const getDeploymentConfig = (
  params: IGetDeploymentConfigParams
): Promise<IApiResponse<IGetDeploymentConfigResponse>> => {
  return docBuilderClient
    .get('/deploymentConfig', {
      params,
    })
    .then(requestHelpers.getResult);
};

const createMockDeployment = async (
  body: ICreateDeploymentConfigBody
): Promise<any> => {
  return docBuilderClient
    .post('/deploymentConfig', body)
    .then(requestHelpers.getResult);
};
const updateMockDeployment = async (
  body: IUpdateDeploymentConfigBody
): Promise<any> => {
  return docBuilderClient
    .put(`/deploymentConfig/${body.deploymentConfigId}`, body)
    .then(requestHelpers.getResult);
};

const deleteMockDeployment = async (
  params: IDeleteDeploymentConfigParams
): Promise<any> => {
  return docBuilderClient
    .delete(`/deploymentConfig/${params.deploymentConfigId}`)
    .then(requestHelpers.getResult);
};

const getMockUrl = async (
  params: IGetMockUrlParams
): Promise<IApiResponse<IGetMockUrlResponse>> => {
  return docBuilderClient
    .get('/deploymentConfig/mockUrl', { params })
    .then(requestHelpers.getResult);
};

export default {
  getDeploymentConfig,
  createMockDeployment,
  updateMockDeployment,
  deleteMockDeployment,
  getMockUrl,
};
