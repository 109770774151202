const setCookie = (cname: string, cvalue: string, expdays?: number) => {
  let expires = '';
  if (expdays) {
    let d = new Date();
    d.setTime(d.getTime() + expdays * 24 * 60 * 60 * 1000);
    expires = ';expires=' + d.toUTCString();
  }
  document.cookie = `${cname}=${cvalue}${expires};path=/`;
};

const getCookie = (cname: string) => {
  const name = `${cname}=`;
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
};

export default {
  setCookie,
  getCookie,
  deleteCookie,
};
