import { Locale } from 'antd/lib/locale-provider';

export interface IPermission {
  app: string;
  resource: string;
  action?: string;
}

export interface IRoute {
  exact?: boolean;
  path: string;
  name: string;
  id?: number;
  key?: string;
  title?: string;
  external?: boolean;
  component?: React.ElementType;
  permissions?: IPermission[];
  icon?: React.ComponentType<{ className?: string }>;
  children?: IRoute[];
}

export interface IRegionItem {
  key: string;
  name: string;
  flag: string;
  antdLocale: Locale;
}

export interface IRegion {
  [key: string]: IRegionItem;
}

export interface IPagination {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

export interface INamespace {
  name: string;
  code: string;
  urlPath: string;
}

export enum ENamespace {
  draft = 'DRAFT',
  approved = 'APPROVED',
}
export type IApiResponse<DataType> = {
  code: string;
  message: string;
  data: DataType;
};
