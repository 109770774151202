import { EReleaseStatus } from 'interfaces/release';

const mapStatusToColor = (status: EReleaseStatus): string => {
  switch (status) {
    case EReleaseStatus.APPROVED:
      return 'green';
    case EReleaseStatus.REJECTED:
      return 'red';
    default:
      return 'yellow';
  }
};

export default {
  mapStatusToColor,
};
