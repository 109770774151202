import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classNames from 'classnames';
import { IRoute } from 'interfaces';
import { commonHooks } from 'hooks';
import logo from 'assets/images/logo.png';
import { menuHelper } from 'helpers';

const { Sider, Footer } = Layout;
const { SubMenu } = Menu;
const { useAppMenu } = commonHooks;
const { accumulatePath } = menuHelper;

interface AppSiderProps {
  filteredNavigation: IRoute[];
  collapsed: boolean;
}

const AppSider: React.FC<AppSiderProps> = props => {
  // Get selectedKey, openKey from route & pathname
  const { filteredNavigation, collapsed } = props;

  const { selectedKey, openKey } = useAppMenu(filteredNavigation);
  const [openKeys, setOpenKeys] = useState(openKey);

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    setOpenKeys([...openKeys, ...accumulatePath(selectedKey)]);
  }, [selectedKey]);

  const renderMenu = (routers: IRoute[]) => {
    return routers.map((route: IRoute) => {
      if (!route.children || route.children.length === 0) {
        if (!route.icon)
          return (
            <Menu.Item key={route.path}>
              {route?.external ? (
                <a href={route.path} rel="noopener noreferrer">
                  <span>{route.name}</span>
                </a>
              ) : (
                <Link to={route.path}>
                  <span>{route.name}</span>
                </Link>
              )}
            </Menu.Item>
          );
        return (
          <Menu.Item key={route.path}>
            {route?.external ? (
              <a href={route.path} rel="noopener noreferrer">
                <route.icon className="app-icon" />
                <span>{route.name}</span>
              </a>
            ) : (
              <Link to={route.path}>
                <route.icon className="app-icon" />
                <span>{route.name}</span>
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <SubMenu
          key={route.path}
          title={
            route.component ? (
              <Link to={route.path}>
                {route.icon && <route.icon className="app-icon" />}
                <span>{route.name}</span>
              </Link>
            ) : // eslint-disable-next-line jsx-a11y/anchor-is-valid
            route?.external ? (
              <a href={route.path}>
                {route.icon && <route.icon className="app-icon" />}
                <span>{route.name}</span>
              </a>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a>
                {route.icon && <route.icon className="app-icon" />}
                <span>{route.name}</span>
              </a>
            )
          }
        >
          {renderMenu(route.children)}
        </SubMenu>
      );
    });
  };

  return (
    <Sider
      className={classNames({
        'app-sider': true,
        collapsed,
      })}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={330}
    >
      <div className="app-logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <Menu
        className="app-menu"
        theme="dark"
        mode="inline"
        defaultOpenKeys={[...openKey]}
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {renderMenu(filteredNavigation)}
      </Menu>

      {!collapsed && (
        <Footer className="app-footer">API Doc © {window.APP_VERSION}</Footer>
      )}
    </Sider>
  );
};

export default AppSider;
