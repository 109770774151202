import { requestServices } from 'services';
import {
  IGetProjectsParams,
  IGetProjectsResponse,
  IGetVersionsResponse,
  IGetVersionsParams,
  IGetProjectDetailParams,
  IGetProjectDetailResponse,
  ISubscribeNotificationParams,
  IUpdateProjectPathParams,
  ISubscribeNotificationBody,
  IDeleteProjectByIdParams,
  IGetProjectByVersionParams,
  IGetProjectByVersionResponse,
} from 'interfaces';
import { requestHelpers } from 'helpers';

const { docBuilderClient } = requestServices;

const getProjects = (
  params: IGetProjectsParams
): Promise<IGetProjectsResponse> => {
  return docBuilderClient
    .get('/project', {
      params,
    })
    .then(requestHelpers.getResult);
};

const getVersions = (
  params: IGetVersionsParams
): Promise<IGetVersionsResponse> => {
  return docBuilderClient
    .get(`/project/${params.projectCode}/versions`, {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        searchKey: params.searchKey,
        namespace: params.namespace,
        type: params.type,
        fromTime: params.fromTime,
        toTime: params.toTime,
      },
    })
    .then(requestHelpers.getResult);
};

const getProjectDetail = (
  params: IGetProjectDetailParams
): Promise<IGetProjectDetailResponse> => {
  return docBuilderClient
    .get(`/project/${params.projectCode}`)
    .then(requestHelpers.getResult);
};

const deleteProjectById = (params: IDeleteProjectByIdParams): Promise<any> => {
  return docBuilderClient
    .delete(`/project/${params.projectId}`)
    .then(requestHelpers.getResult);
};

const updateProjectPath = (params: IUpdateProjectPathParams): Promise<any> => {
  return docBuilderClient
    .post(
      `/project/${params.projectId}/updateProjectPath`,
      {},
      {
        params: {
          newProjectPath: params.newProjectPath,
        },
      }
    )
    .then(requestHelpers.getResult);
};

const subscribeNotification = (
  params: ISubscribeNotificationParams,
  body: ISubscribeNotificationBody
): Promise<any> => {
  return docBuilderClient
    .post(`/project/${params.projectId}/subscribeNotification`, body)
    .then(requestHelpers.getResult);
};

const getProjectByVersion = (
  params: IGetProjectByVersionParams
): Promise<IGetProjectByVersionResponse> => {
  return docBuilderClient
    .get(`/project/searchProjectByVersion`, {
      params,
    })
    .then(requestHelpers.getResult);
};

export default {
  getProjects,
  getVersions,
  getProjectDetail,
  updateProjectPath,
  deleteProjectById,
  subscribeNotification,
  getProjectByVersion,
};
