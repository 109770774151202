import { useState, useEffect, MutableRefObject } from 'react';
import { browserHistory } from 'helpers';
import commonConstants from 'constants/common';
import commonHelpers from 'helpers/common';
import menuHelper from 'helpers/menu';
import { IRoute } from 'interfaces';

const { TABLET_WIDTH } = commonConstants;
const { getWindowDimensions } = commonHelpers;
const { accumulatePath } = menuHelper;

const useAppMenu = (items: IRoute[]) => {
  const { location } = browserHistory;
  let selectedKey = location.pathname;

  const openKey = accumulatePath(selectedKey);

  return { selectedKey, openKey };
};

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    const handleResize = () => setDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...dimensions, isTabletView: dimensions.width <= TABLET_WIDTH };
};

const config: MutationObserverInit = {
  childList: true,
  subtree: true,
};

function useMutationObserver(
  ref: MutableRefObject<HTMLElement | null>,
  callback: MutationCallback,
  options: MutationObserverInit = config
): void {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);

      return () => {
        observer.disconnect();
      };
    }
  }, [callback, options]);
}

export { useMutationObserver };

export default {
  useAppMenu,
  useWindowDimensions,
  useMutationObserver,
};
