import cookieHelpers from 'helpers/cookie';
import { cookieConstants, namespaceConstants } from 'constants/index';

const { CURRENT_NAMESPACE } = cookieConstants;
const { DEFAULT_NAMESPACE } = namespaceConstants;

const getCurrentNamespace = () => {
  return cookieHelpers.getCookie(CURRENT_NAMESPACE) || DEFAULT_NAMESPACE.code;
};

const setCurrentNamespace = (namespace: string) => {
  cookieHelpers.setCookie(CURRENT_NAMESPACE, namespace, 365);
};

const changeCurrentNamespace = (namespace: string) => {
  setCurrentNamespace(namespace);
};

export default {
  getCurrentNamespace,
  setCurrentNamespace,
  changeCurrentNamespace,
};
