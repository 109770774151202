export interface IDeployImageBody {
  projectCode: string;
  version: string;
}

export interface IDeleteVersionParams {
  projectCode: string;
  version: string;
}

export interface IDeleteVersionByIdParams {
  id: number;
}

export interface IDeleteAllVersion {
  projectCode: string;
}
export type IDeployImageResponse = any;

export interface IDeployEnvironmentImageParams {
  projectCode: string;
  version: string;
  deployEnv: string;
}

export enum EGenerateProtoBufMode {
  PREVIEW = 'PREVIEW',
  SAVE = 'SAVE',
}
export interface IGenerateProtobufParams {
  versionId: number;
  gitBranch?: string;
  mode?: EGenerateProtoBufMode;
}

export interface IProtobufFile {
  filePath: string;
  fileContent: string;
}
export interface IGenerateProtobufResponse {
  files: IProtobufFile[];
}

export interface IFolderTree {
  title: string;
  key: string;
  fileContent?: string;
  children?: IFolderTree[];
}

export interface IVersionLabel {
  id: number;
  label: string;
}

export interface ISearchVersionParams {
  page?: number;
  pageSize?: number;
  projectId: number;
  searchKey?: string;
}

export interface ISearchVersionResponse {
  versions: IVersionLabel[];
}

export interface IGetFileContentByCodeParams {
  projectCode: string;
  versionNamespace: string;
  versionTag: string;
  mockUrl?: string;
}

export interface IGetFileContentByIdParams {
  versionId: number;
  mockUrl?: string;
}
