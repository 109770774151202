import { useEffect, useState } from 'react';
import {
  getReleases,
  getRelease,
  IRelease,
  IMissingRelease,
  getMissingRelease,
  IDeploymentDetail,
  getDeploymentDetail,
} from 'services/apis/release';

export function useListRelease(): {
  onStagings: string[];
  listReleased: string[];
  loading: boolean;
} {
  const [onStagings, setOnStagings] = useState<string[]>([]);
  const [listReleased, setListReleased] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReleases()
      .then(res => {
        setOnStagings(res.on_stagings);
        setListReleased(res.list_released);
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    onStagings,
    listReleased,
    loading,
  };
}

export function useRelease(
  fixVersion: string,
  isReleased: boolean
): {
  release: IRelease | null;
  loading: boolean;
  isOwner: boolean;
  changeIsOwner: () => void;
  reload: () => void;
} {
  const [loading, setLoading] = useState(true);
  const [release, setRelease] = useState<IRelease | null>(null);
  const [isOwner, setIsOwner] = useState(true);
  useEffect(() => {
    getReleaseDetail();
  }, [fixVersion, isReleased, isOwner]);

  const getReleaseDetail = () => {
    setLoading(true);
    getRelease(fixVersion, { is_released: isReleased, is_owner: isOwner })
      .then(res => setRelease(res.release))
      .finally(() => setLoading(false));
  };

  const changeIsOwner = () => {
    setIsOwner(!isOwner);
  };

  const reload = () => {
    getReleaseDetail();
  };

  return {
    release,
    loading,
    isOwner,
    changeIsOwner,
    reload,
  };
}

export function useReleaseDetail(
  deploymentId: number
): {
  deploymentDetail: IDeploymentDetail | null;
  loading: boolean;
  reload: () => void;
} {
  const [loading, setLoading] = useState(true);
  const [
    deploymentDetail,
    setDeploymentDetail,
  ] = useState<IDeploymentDetail | null>(null);
  useEffect(() => {
    getDetail();
  }, [deploymentId]);

  const getDetail = () => {
    setLoading(true);
    getDeploymentDetail(deploymentId)
      .then(res => setDeploymentDetail(res.detail))
      .finally(() => setLoading(false));
  };

  const reload = () => {
    getDetail();
  };

  return {
    deploymentDetail,
    loading,
    reload,
  };
}

export function useMissingRelease(
  fixVersion: string,
  isReleased: boolean
): {
  release: IMissingRelease | null;
  loading: boolean;
} {
  const [loading, setLoading] = useState(true);
  const [release, setRelease] = useState<IMissingRelease | null>(null);
  useEffect(() => {
    setLoading(true);
    getMissingRelease(fixVersion, isReleased)
      .then(res => setRelease(res.release))
      .finally(() => setLoading(false));
  }, [fixVersion, isReleased]);

  return {
    release,
    loading,
  };
}
