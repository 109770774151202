import moment from 'moment';
import { timeConstants } from 'constants/index';

const { HOUR_DAY_FORMAT } = timeConstants;

const formatTime = (dt: string, format: string = HOUR_DAY_FORMAT): string => {
  return moment(dt).format(format);
};
export default {
  formatTime,
};
