import { useState, useEffect, useContext } from 'react';
import { projectServices } from 'services';
import {
  IVersion,
  IProject,
  IProjectDetail,
  IGetVersionsParams,
  IGetProjectDetailParams,
  IDeleteProjectByIdParams,
  IUpdateProjectPathParams,
  ISubscribeNotificationParams,
  ISubscribeNotificationBody,
  IGetProjectByVersionParams,
  IProjectByVersion,
} from 'interfaces';
import { namespaceHelper } from 'helpers';
import { StoreContext } from 'contexts';

const useProject = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchProjects = async () => {
    try {
      setLoading(true);
      const res = await projectServices.getProjects({
        namespace: namespaceHelper.getCurrentNamespace(),
      });
      setProjects(res.data.projects);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return {
    projects,
    loading,
    fetchProjects,
  };
};

export interface IPagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

const useVersions = ({
  queryParams,
  startFetching = true,
}: {
  queryParams: IGetVersionsParams;
  startFetching?: boolean;
}) => {
  const [versions, setVersion] = useState<IVersion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IGetVersionsParams>(queryParams);
  const [pagination, setPagination] = useState<IPagination>({
    current: queryParams.page,
    pageSize: queryParams.pageSize,
    total: 0,
  });

  const { namespace } = useContext(StoreContext);

  const fetchVersions = async () => {
    if (startFetching) {
      try {
        setLoading(true);
        const res = await projectServices.getVersions({
          ...params,
          namespace: namespace.code,
        });
        setVersion(res.data.versions);
        setPagination({
          current: res.data.pagination.currentPage,
          pageSize: res.data.pagination.pageSize,
          total: res.data.pagination.totalItems,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchVersions();
  }, [params, startFetching, namespace]);

  return {
    versions,
    loading,
    fetchVersions,
    setParams,
    params,
    pagination,
    setPagination,
  };
};

const useProjectDetail = ({
  queryParams,
  startFetching,
}: {
  queryParams: IGetProjectDetailParams;
  startFetching: boolean;
}) => {
  const [generalInformation, setGeneralInformation] = useState<
    IProjectDetail
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IGetProjectDetailParams>(queryParams);

  const fetchProjectDetail = async () => {
    if (startFetching) {
      try {
        setLoading(true);
        const res = await projectServices.getProjectDetail(params);
        setGeneralInformation(res.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProjectDetail();
  }, [params, startFetching]);

  return {
    generalInformation,
    loading,
    fetchProjectDetail,
    setParams,
    params,
  };
};

const useDeleteProject = () => {
  const [loading, setLoading] = useState(false);

  const deleteProject = async (params: IDeleteProjectByIdParams) => {
    try {
      setLoading(true);
      await projectServices.deleteProjectById(params);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    deleteProject,
  };
};

const useUpdateProjectPath = () => {
  const [loading, setLoading] = useState(false);

  const updateProjectPath = async (params: IUpdateProjectPathParams) => {
    try {
      setLoading(true);
      await projectServices.updateProjectPath(params);
    } finally {
      setLoading(false);
    }
  };
  return { loading, updateProjectPath };
};

const useSubscribeNotification = () => {
  const [loading, setLoading] = useState(false);

  const subscribeNotification = async (
    params: ISubscribeNotificationParams,
    body: ISubscribeNotificationBody
  ) => {
    try {
      setLoading(true);
      await projectServices.subscribeNotification(params, body);
    } finally {
      setLoading(false);
    }
  };
  return { loading, subscribeNotification };
};

const useAllProject = ({
  queryParams,
}: {
  queryParams: IGetProjectByVersionParams;
}) => {
  const [projects, setProjects] = useState<IProjectByVersion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IGetProjectByVersionParams>(queryParams);
  const [pagination, setPagination] = useState<IPagination>({
    current: queryParams.page,
    pageSize: queryParams.pageSize,
    total: 0,
  });

  const { namespace } = useContext(StoreContext);

  const fetchVersions = async () => {
    try {
      setLoading(true);
      const res = await projectServices.getProjectByVersion({
        ...params,
        namespace: namespace.code,
      });
      setProjects(res.data.projects);
      setPagination({
        current: res.data.pagination.currentPage,
        pageSize: res.data.pagination.pageSize,
        total: res.data.pagination.totalItems,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVersions();
  }, [params, namespace]);

  return {
    projects,
    loading,
    fetchVersions,
    setParams,
    params,
    pagination,
    setPagination,
  };
};

export default {
  useProject,
  useVersions,
  useProjectDetail,
  useDeleteProject,
  useUpdateProjectPath,
  useSubscribeNotification,
  useAllProject,
};
