import React, { Suspense, useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { IRoute } from 'interfaces';
import AppBreadcrumb from 'containers/AppLayout/AppBreadcrumb';

const { Content } = Layout;

interface AppContentProps {
  filteredRoutes: IRoute[];
}

const AppContent: React.FC<AppContentProps> = props => {
  const { filteredRoutes } = props;

  const flattenRoutes = useMemo(() => {
    const result: IRoute[] = [];
    let queue: IRoute[] = [...filteredRoutes];

    while (queue.length > 0) {
      const temp: any = queue.shift();
      if (temp?.children) {
        queue = [...temp.children, ...queue];
      }
      result.push(temp);
    }
    return result.map(route => ({ ...route, children: undefined }));
  }, [filteredRoutes]);

  return (
    <Content className="app-content">
      <Suspense fallback={null}>
        <Switch>
          {flattenRoutes.map(({ component: Component, ...rest }) => {
            return (
              <Route
                {...rest}
                key={uuidv4()}
                render={routeProps => {
                  const crumbs = flattenRoutes
                    // Get all routes that contain the current one
                    .filter(({ path }) => {
                      return !!path && routeProps.match.path.includes(path);
                    })
                    // Swap out any dynamic routes with their param values
                    // E.g. "/products/:id" will become "/products/1"
                    .map(({ path = '', name }) => {
                      return {
                        path: Object.keys(routeProps.match.params).length
                          ? Object.keys(routeProps.match.params).reduce(
                              (path, param) =>
                                path.replace(
                                  `:${param}`,
                                  routeProps.match.params[param]!
                                ),
                              path
                            )
                          : path,
                        breadcrumbName: name,
                      };
                    })
                    .sort((first, second) => {
                      return first.path.length - second.path.length;
                    });
                  return (
                    <>
                      <AppBreadcrumb crumbs={crumbs} />
                      {Component ? <Component {...routeProps} /> : null}
                    </>
                  );
                }}
              />
            );
          })}
          <Redirect from="/" to="/404" />
        </Switch>
      </Suspense>
    </Content>
  );
};

export default AppContent;
