import { useState, useEffect } from 'react';

import {
  ICreateDeploymentConfigBody,
  IDeleteDeploymentConfigParams,
  IDeploymentConfig,
  IGetDeploymentConfigParams,
  IGetMockUrlParams,
} from 'interfaces';
import { deploymentConfigServices } from 'services';
import { IUpdateDeploymentConfigBody } from '../interfaces/deploymentConfig';

const useDeploymentConfig = (defaultParams: IGetDeploymentConfigParams) => {
  const [devDeploymentConfigs, setDevDeploymentConfigs] = useState<
    IDeploymentConfig[]
  >([]);
  const [stagingDeploymentConfigs, setStagingDeploymentConfigs] = useState<
    IDeploymentConfig[]
  >([]);
  const [test1DeploymentConfigs, setTest1DeploymentConfigs] = useState<
    IDeploymentConfig[]
  >([]);
  const [params, setParams] = useState<IGetDeploymentConfigParams>(
    defaultParams
  );
  const [loading, setLoading] = useState(false);

  const fetchDeploymentConfig = async () => {
    try {
      setLoading(true);
      const res = await deploymentConfigServices.getDeploymentConfig(params);
      const data = res.data;
      setDevDeploymentConfigs(data.deploymentConfigsOfDev);
      setTest1DeploymentConfigs(data.deploymentConfigsOfTest1);
      setStagingDeploymentConfigs(data.deploymentConfigsOfStaging);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeploymentConfig();
  }, [params]);
  return {
    devDeploymentConfigs,
    stagingDeploymentConfigs,
    test1DeploymentConfigs,
    setParams,
    loading,
    fetchDeploymentConfig,
  };
};

const useCreateMockDeployment = () => {
  const [loading, setLoading] = useState(false);

  const createMockDeployment = async (body: ICreateDeploymentConfigBody) => {
    try {
      setLoading(true);
      await deploymentConfigServices.createMockDeployment(body);
    } finally {
      setLoading(false);
    }
  };
  return { loading, createMockDeployment };
};

const useDeleteMockDeployment = () => {
  const [loading, setLoading] = useState(false);

  const deleteMockDeployment = async (
    params: IDeleteDeploymentConfigParams
  ) => {
    try {
      setLoading(true);
      await deploymentConfigServices.deleteMockDeployment(params);
    } finally {
      setLoading(false);
    }
  };
  return { loading, deleteMockDeployment };
};

const useMockUrl = (defaultParams: IGetMockUrlParams) => {
  const [loading, setLoading] = useState(false);
  const [mockUrls, setMockUrls] = useState<string[]>([]);

  const fetchMockUrl = async () => {
    try {
      setLoading(true);
      const res = await deploymentConfigServices.getMockUrl(defaultParams);
      setMockUrls(res.data.mockUrls);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMockUrl();
  }, []);

  return {
    loading,
    mockUrls,
  };
};

const useUpdateDeploymentConfig = () => {
  const [loading, setLoading] = useState(false);

  const updateDeploymentConfig = async (body: IUpdateDeploymentConfigBody) => {
    try {
      setLoading(true);
      await deploymentConfigServices.updateMockDeployment(body);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    updateDeploymentConfig,
  };
};

export default {
  useDeploymentConfig,
  useCreateMockDeployment,
  useDeleteMockDeployment,
  useMockUrl,
  useUpdateDeploymentConfig,
};
