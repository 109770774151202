import { Dispatch, SetStateAction, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Table, notification, Tooltip, Tag } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { CopyOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { parse } from '@stoplight/yaml';

import { IVersion, IGetVersionsParams, IProject, ENamespace } from 'interfaces';
import { t } from 'helpers/i18n';
import { commonConstants } from 'constants/index';
import { stringHelper } from 'helpers';
import DeleteButton from './DeleteButton';
import { StoreContext } from 'contexts';
import { versionServices } from 'services';
import openApi2pdf from 'helpers/openApi2pdf';

const { PATH } = commonConstants;
export interface IProjectTableProp extends TableProps<IVersion> {
  project: IProject;
  versions: IVersion[];
  loading: boolean;
  setParams: Dispatch<SetStateAction<IGetVersionsParams>>;
  fetchVersions: () => Promise<void>;
}

const ProjectTable = ({
  project,
  versions,
  fetchVersions,
  ...rest
}: IProjectTableProp) => {
  const { namespace } = useContext(StoreContext);

  const downloadDocFromFile = (apiDoc: string, name?: string) => {
    const apiDocObj = parse(apiDoc);
    openApi2pdf.generatePdf(apiDocObj as Object).then(pdf => {
      pdf.download(`${name}.pdf`);
    });
  };

  const downloadApiDoc = async (versionId: number, name?: string) => {
    try {
      notification.info({ message: t('Downloading') });
      if (!name) {
        name = project?.path.split('/')[project?.path.split('/').length - 1];
      }
      const apiDoc = await versionServices.getFileContentById({ versionId });
      setTimeout(() => {
        downloadDocFromFile(apiDoc, name);
      }, 0);
    } catch (e) {
      console.error(e);
      notification.error({ message: t('SomethingWentWrong') });
    }
  };

  const columns: ColumnsType<IVersion> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record) => {
        let docUrl = PATH.project.doc
          .replace(
            ':projectPath',
            stringHelper.mapProjectCodeToPath(project.code)
          )
          .replace(':namespace', namespace.urlPath)
          .replace(':versionTag', record.gitlabCommitTag);

        if (record.subPath) {
          docUrl += '/' + record.subPath;
        }
        return (
          <Link to={docUrl} title="Docs detail">
            <Space>
              {record.id}
              <EyeOutlined />
            </Space>
          </Link>
        );
      },
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      width: 300,
      key: 'description',
      render: description => {
        return (
          <div
            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            title={t('Description')}
          >
            {description}
          </div>
        );
      },
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      width: 1,
      key: 'Type',
      render: (text: string) => {
        if (text) {
          return <Tag>{text}</Tag>;
        }
        return null;
      },
    },
    ...(namespace.code === ENamespace.draft
      ? [
          {
            title: t('Issue'),
            dataIndex: 'issueCode',
            width: 100,
            key: 'issueCode',
            render: (issueCode: any) => {
              if (!issueCode) {
                return null;
              }
              const jiraIssueURL =
                commonConstants.BASE_JIRA_ISSUE_URL + issueCode;
              return (
                <a
                  href={jiraIssueURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={jiraIssueURL}
                >
                  {issueCode}
                </a>
              );
            },
          },
        ]
      : []),
    {
      title: t('CreatedAt'),
      dataIndex: 'createdAt',
      width: 100,
      key: 'createdAt',
    },
    {
      title: t('CreatedBy'),
      dataIndex: 'createdBy',
      width: 100,
      key: 'createdBy',
    },
    {
      title: 'Gitlab commit',
      dataIndex: 'gitlabCommitTag',
      key: 'gitlabCommitTag',
      width: 50,
      render: gitlabCommitTag => {
        const gitlabCommitUrl =
          commonConstants.BASE_GITLAB_COMMIT_URL + gitlabCommitTag;
        return (
          <Space>
            <a
              href={gitlabCommitUrl}
              target="_blank"
              rel="noopener noreferrer"
              title={t('ViewChange')}
            >
              {gitlabCommitTag}
            </a>
          </Space>
        );
      },
    },
    {
      title: 'Gitlab MR',
      dataIndex: 'gitlabMergeRequest',
      key: 'gitlabMergeRequest',
      width: 50,
      render: gitlabMergeRequest => {
        const gitlabCommitUrl =
          commonConstants.BASE_GITLAB_MR_URL + gitlabMergeRequest;
        return (
          <Space>
            <a
              href={gitlabCommitUrl}
              target="_blank"
              rel="noopener noreferrer"
              title={t('ViewChange')}
            >
              {gitlabMergeRequest}
            </a>
          </Space>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <div className="d-flex justify-content-end">
            <Space>
              <Tooltip title={t('CopyDocURL')}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      `${window.DOC_BUILDER_DOMAIN}/version/getFileContentByCode?projectCode=${project.code}&versionTag=${record.gitlabCommitTag}&versionNamespace=${namespace.code}`
                    );
                    notification.success({ message: t('CopyDocURLSuccess') });
                  }}
                />
              </Tooltip>
              <Tooltip title={t('DownloadDocFile')}>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    downloadApiDoc(record.id);
                  }}
                />
              </Tooltip>
              <Tooltip title={t('DeleteVersion')}>
                <DeleteButton
                  docVersion={record.gitlabCommitTag}
                  projectCode={project.code}
                  versionId={record.id}
                  onSuccess={fetchVersions}
                />
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <Table dataSource={versions} columns={columns} rowKey="id" {...rest} />
  );
};

export default ProjectTable;
