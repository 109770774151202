import React, { useState, lazy, useEffect, useLayoutEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import { browserHistory } from 'helpers';
import '@stoplight/elements/styles.min.css';
import './App.less';
import './App.scss';
import Page403 from 'containers/shared/Page403';
import Page404 from 'containers/shared/Page404';
import Page500 from 'containers/shared/Page500';
import PrivateRoute from 'components/shared/PrivateRoute';
import AppLayout from 'containers/AppLayout';
import { commonConstants } from 'constants/index';
import TekoID from 'teko-oauth2';
const ApiDocs = lazy(() => import('containers/ApiDocs'));

const { IAM_CLIENT_ID, IAM_OAUTH_DOMAIN } = window;

const { IAM_SCOPES } = commonConstants;

// const reactTracker = new ReactTracker({
//   // Configure your tracker server and site by providing
//   host: (tracker.appId && tracker.host) || '',
//   urlServeJsFile: (tracker.appId && tracker.jsFile) || '',
//   appId: tracker.appId || '',
// });

const postUrlMessage = () => {
  const path = window.location.href.split(window.location.host)[1];
  // The message format is not a standard. I just suddenly thought of it.
  window.parent.postMessage({ type: 'url', message: path }, '*');
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    TekoID.init({
      clientId: IAM_CLIENT_ID,
      scopes: IAM_SCOPES,
      oauthDomain: IAM_OAUTH_DOMAIN,
      redirectUri: window.location.origin,
    }).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const { location } = window;
    if (window.IS_REDIRECT && location.hostname === window.REDIRECT_FROM) {
      location.href = `${location.protocol}//${window.REDIRECT_TO}${location.pathname}`;
    }
  }, []);

  // announce url to the parent frame, which is to sync url between frames
  useLayoutEffect(() => {
    // do nothing when the website is not embeded in any frames
    if (window.self === window.top) {
      return;
    }

    // use postMessage to send the initial url to the parent frame
    postUrlMessage();

    ['go', 'back', 'forward', 'pushState', 'replaceState'].forEach(method => {
      // use Proxy to listen on url changes
      // @ts-ignore method variable is consistent
      window.history[method] = new Proxy(window.history[method], {
        apply: (target, thisArg, argArray) => {
          // @ts-ignore solve "any" stuff
          const output = target.apply(thisArg, argArray);

          // use postMessage to send urls to the parent frame
          postUrlMessage();

          return output;
        },
      });
    });

    const listener = () => {
      postUrlMessage();
    };

    // listen to back and forward events
    window.addEventListener('popstate', listener);

    return () => {
      window.removeEventListener('popstate', listener);
    };
  }, []);

  return loading ? (
    <Spin className="app-spin" />
  ) : (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/403" component={Page403} />
        <Route exact path="/404" component={Page404} />
        <Route exact path="/500" component={Page500} />
        <PrivateRoute
          path="/project-doc/:namespace/:projectPath+/version/:versionTag"
          component={ApiDocs}
        />
        <PrivateRoute path="/" component={AppLayout} />
      </Switch>
    </Router>
  );
};

export default App;
