import React, { useContext, useState } from 'react';
import {
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Form,
  Button,
  Typography,
  notification,
  Popconfirm,
  Spin,
} from 'antd';
import { debounce } from 'lodash';

import { t } from 'helpers/i18n';
import { projectHooks } from 'hooks';
import { IGetVersionsParams, IProject } from 'interfaces';
import timeConstant from 'constants/time';
import { EVersionType } from 'constants/version';
import { useHistory } from 'react-router-dom';
import { StoreContext } from '../../contexts/index';
import UpdateProjectPathModal from 'components/UpdateProjectPathModal';

const { Text, Title } = Typography;

export interface IProjectProjectCardProp {
  project?: IProject;
  params: IGetVersionsParams;
  setParams: React.Dispatch<React.SetStateAction<IGetVersionsParams>>;
}
const ProjectCard = ({
  project,
  setParams,
  params,
}: IProjectProjectCardProp) => {
  const {
    generalInformation,
    fetchProjectDetail,
    loading: fetchLoading,
  } = projectHooks.useProjectDetail({
    queryParams: {
      projectCode: project?.code ?? '',
    },
    startFetching: !!project,
  });
  const {
    loading: deleteLoading,
    deleteProject,
  } = projectHooks.useDeleteProject();
  const { subscribeNotification } = projectHooks.useSubscribeNotification();
  const { fetchProjects } = useContext(StoreContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [showUpdatePathModal, setShowUpdatePathModal] = useState(false);

  const handleFormSubmit = (value: any) => {
    const tempValue = { ...value };
    if (tempValue.fromTime) {
      tempValue.fromTime = tempValue.fromTime.unix();
    }
    if (tempValue.toTime) {
      tempValue.toTime = tempValue.toTime.unix();
    }
    tempValue.searchKey = tempValue.searchKey || undefined;

    setParams({ ...params, ...tempValue, page: 1 });
  };

  const handleSearch = (searchKey: string) => {
    setParams({ ...params, searchKey: searchKey, page: 1 });
  };

  const handleChange = () => {
    form.submit();
  };

  const handleDeleteProject = async () => {
    try {
      await deleteProject({ projectId: Number(project?.id) });
      notification.success({ message: t('DeleteSuccess') });
      history.replace('/');
      await fetchProjects();
    } catch (e) {
      console.error(e);
      notification.error({ message: t('SomethingWentWrong') });
    }
  };

  const handleSubscribe = async () => {
    try {
      await subscribeNotification(
        { projectId: Number(project?.id) },
        { subscribe: true }
      );
      fetchProjectDetail();
      notification.success({ message: t('SubscribeSuccess') });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('SomethingWentWrong') });
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await subscribeNotification(
        { projectId: Number(project?.id) },
        { subscribe: false }
      );
      fetchProjectDetail();
      notification.success({ message: t('UnsubscribeSuccess') });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('SomethingWentWrong') });
    }
  };

  return (
    <Spin spinning={deleteLoading || fetchLoading}>
      <Row gutter={8}>
        <Col xxl={4} xl={6} lg={24} className="mb-base">
          <Row>
            <Title level={4}>{t('GeneralInformation')}</Title>
          </Row>
          <Row>
            <Text strong>{t('Path')}: </Text>&nbsp;
            <Text>{generalInformation?.path}</Text>
          </Row>
          <Row>
            <Text strong>{t('NumberOfVersions')}: </Text>&nbsp;
            <Text>{generalInformation?.totalVersions}</Text>
          </Row>
          <Row>
            <Text strong>{t('NumberOfIssues')}: </Text>&nbsp;
            <Text>{generalInformation?.totalIssues}</Text>
          </Row>
          <Row>
            <Text strong>{t('DependencyService')}: </Text>&nbsp;
            <Text>{generalInformation?.dependencyService}</Text>
          </Row>
        </Col>
        <Col xxl={10} xl={6} lg={24} className="mb-base">
          <Row>
            <Title level={4}>{t('Action')}</Title>
          </Row>
          <Row>
            {/* <Col xxl={12} xl={24}>
              <Button
                className="d-block px-0"
                type="link"
                onClick={() => {
                  history.push(
                    `/project/${namespace.urlPath}/${mapProjectCodeToPath(
                      project?.code || ''
                    )}/mock-deployment`
                  );
                }}
              >
                {t('ShowMockDeployment')}
              </Button>
            </Col> */}
            {/* <Col xxl={12} xl={24}>
              <Button
                className="d-block px-0"
                type="link"
                onClick={() => {
                  history.push(
                    `/project/${namespace.urlPath}/${mapProjectCodeToPath(
                      project?.code || ''
                    )}/protobuf`
                  );
                }}
              >
                {t('GenerateProtobuf')}
              </Button>
            </Col> */}
            {/* <Button className="d-block px-0" type="link" disabled>
                {t('GenerateRestApiCode')}
              </Button>
              <Button className="d-block px-0" type="link" disabled>
                {t('GenerateRestApiTest')}
              </Button> */}

            <Col xxl={12} xl={24} className="mb-base">
              {generalInformation?.isSubscribe ? (
                <Popconfirm
                  onConfirm={handleUnsubscribe}
                  title={t('YouWillNotReceiveNoti')}
                >
                  <Button className="d-block px-0" type="link">
                    {t('UnsubscribeNotificationOnChange')}
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  onConfirm={handleSubscribe}
                  title={t('YouWillReceiveNoti')}
                >
                  <Button className="d-block px-0" type="link">
                    {t('SubscribeNotificationOnChange')}
                  </Button>
                </Popconfirm>
              )}
            </Col>
            {/* <Col xxl={12} xl={24} className="mb-base">
              <Button className="d-blo px-0" type="link" disabled>
                {t('SetDependencyService')}
              </Button>
            </Col> */}
            {/* <Col xxl={12} xl={24} className="mb-base">
              <Button
                className="d-block px-0"
                type="link"
                onClick={() => {
                  setShowUpdatePathModal(true);
                }}
              >
                {t('UpdateProjectPath')}
              </Button>
            </Col> */}
            <Col xxl={12} xl={24}>
              <Popconfirm
                onConfirm={handleDeleteProject}
                title={t('AreYouSureDeleteProject')}
              >
                <Button className="d-block px-0" type="link">
                  {t('RemoveProject')}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
        <Col xxl={10} xl={12} lg={24}>
          <Form form={form} onFinish={handleFormSubmit}>
            <Row style={{ marginTop: 50 }} gutter={16}>
              <Col xxl={8} xs={24}>
                <Form.Item name="type">
                  <Select
                    className="w-100"
                    placeholder={t('VersionType')}
                    onChange={handleChange}
                    allowClear
                  >
                    <Select.Option value={EVersionType.WRITE_API_DESIGN}>
                      WRITE API DESIGN
                    </Select.Option>
                    <Select.Option value={EVersionType.WRITE_API_DOCUMENT}>
                      WRITE API DOCUMENT
                    </Select.Option>
                    <Select.Option value={EVersionType.IN_REVIEW_DESIGN}>
                      IN REVIEW DESIGN
                    </Select.Option>
                    <Select.Option value={EVersionType.IN_REVIEW_DOCUMENT}>
                      IN REVIEW DOCUMENT
                    </Select.Option>
                    <Select.Option value={EVersionType.APPROVED_DESIGN}>
                      APPROVED DESIGN
                    </Select.Option>
                    <Select.Option value={EVersionType.APPROVED_DOCUMENT}>
                      APPROVED DOCUMENT
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={8} xs={12}>
                <Form.Item name="fromTime" label={t('From')}>
                  <DatePicker
                    className="w-100"
                    format={timeConstant.DAY_FORMAT}
                    onChange={handleChange}
                    allowClear
                    disabledDate={(current: moment.Moment) => {
                      const toDate = form.getFieldValue('toTime');
                      if (toDate) {
                        return current.isAfter(toDate, 'D');
                      }
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={8} xs={12}>
                <Form.Item name="toTime" label={t('To')}>
                  <DatePicker
                    format={timeConstant.DAY_FORMAT}
                    onChange={handleChange}
                    allowClear
                    className="w-100"
                    disabledDate={(current: moment.Moment) => {
                      const fromDate = form.getFieldValue('fromTime');
                      if (fromDate) {
                        return current.isBefore(fromDate, 'D');
                      }
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="w-100">
              <Form.Item name="searchKey">
                <Input.Search
                  className="w-100"
                  type="search"
                  onChange={debounce(handleChange, 500)}
                  onSearch={handleSearch}
                  placeholder="Search by commit, jira issue, user, api, field name,..."
                />
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
      {showUpdatePathModal && (
        <UpdateProjectPathModal
          visible={showUpdatePathModal}
          setVisible={setShowUpdatePathModal}
          project={project}
        />
      )}
    </Spin>
  );
};

export default ProjectCard;
