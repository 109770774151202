const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const mapProjectNameToPath = (name: string): string => {
  return name.toLowerCase().replace(/\s/g, '_');
};
const mapProjectPathToCode = (name: string): string => {
  return name.replace(/\//g, '___');
};

const mapProjectCodeToPath = (name: string): string => {
  return name.replace(/___/g, '/');
};

export default {
  capitalizeFirstLetter,
  mapProjectPathToCode,
  mapProjectCodeToPath,
  mapProjectNameToPath,
};
