import React, { useContext, useEffect, useState, lazy } from 'react';
import { Layout } from 'antd';
import {
  HomeOutlined,
  FileTextOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { userHooks, commonHooks } from 'hooks';
import routes from 'routes';
import { IRoute } from 'interfaces';
import AppSider from './AppSider';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import './AppLayout.scss';
import ApiList from 'containers/ApiList';
import { menuHelper } from 'helpers';
import { StoreContext } from 'contexts';
import { t } from 'helpers/i18n';

const GenProtobuf = lazy(() => import('containers/GenProtobuf'));
const MockDeployment = lazy(() => import('containers/MockDeployment'));
const Home = lazy(() => import('containers/Home'));
const ReleasePage = lazy(() => import('containers/Release/ReleasePage'));
const ReleaseDetail = lazy(() => import('containers/Release/ReleaseDetail'));
const { useWindowDimensions } = commonHooks;

let autoCollapseSider = true;

/**
 * This container is for lifting-up the `AppContent` to the parent node,
 * so we can avoid unnecessary re-calculation when resizing window
 * */
const AppLayoutContainer: React.FC<{ filteredNavigation: IRoute[] }> = ({
  children,
  filteredNavigation,
}) => {
  const { isTabletView } = useWindowDimensions();
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const toggleSider = () => {
    autoCollapseSider = false;
    setSiderCollapsed(collapsed => !collapsed);
  };

  useEffect(() => {
    if (autoCollapseSider) {
      setSiderCollapsed(isTabletView);
    }
  }, [isTabletView]);

  return (
    <Layout className="app-layout">
      <AppSider
        filteredNavigation={filteredNavigation}
        collapsed={siderCollapsed}
      />
      <Layout>
        <AppHeader onClickSiderIcon={toggleSider} />
        {children}
      </Layout>
    </Layout>
  );
};

const AppLayout: React.FC = () => {
  const { projects, namespace } = useContext(StoreContext);

  const additionalRouter: IRoute[] = [
    {
      path: '/project/:namespace/:projectPath+/protobuf',
      name: t('GenerateProtobuf'),
      component: GenProtobuf,
      icon: HomeOutlined,
    },
    {
      path: '/project/:namespace/:projectPath+/mock-deployment',
      name: t('MockDeployment'),
      component: MockDeployment,
    },
    {
      component: ApiList,
      path: `/project/:namespace/:projectPath+`,
      name: t('Project'),
      icon: HomeOutlined,
    },
    {
      path: `/project`,
      name: t('Project'),
      icon: FileTextOutlined,
      component: Home,
    },
  ];
  const { filteredRoutes, filteredNavigation } = userHooks.useAuthorizationData(
    routes as IRoute[]
  );

  const projectRouteTree = menuHelper.buildRouteTree(
    projects,
    namespace,
    ApiList
  );

  return (
    <AppLayoutContainer
      filteredNavigation={[
        ...filteredNavigation,
        {
          path: `/project`,
          name: t('Project'),
          icon: FileTextOutlined,
          children: projectRouteTree,
        },
        {
          path: '/releases',
          name: t('Releases'),
          icon: RocketOutlined,
          children: [
            {
              exact: true,
              path: '/releases/staging',
              name: t('ReleasedStaging'),
            },
            {
              exact: true,
              path: '/releases/production',
              name: t('ReleasedProduction'),
            },
          ],
        },
      ]}
    >
      <AppContent
        filteredRoutes={[
          ...filteredRoutes,
          ...additionalRouter,
          {
            path: '/releases/staging',
            component: ReleasePage,
            exact: true,
            name: t('ReleasedStaging'),
          },
          {
            path: '/releases/production',
            component: ReleasePage,
            exact: true,
            name: t('ReleasedProduction'),
          },
          {
            path: '/releases/detail/:deploymentId',
            component: ReleaseDetail,
            exact: true,
            name: t('ReleaseDetail'),
          },
        ]}
      />
    </AppLayoutContainer>
  );
};

export default AppLayout;
