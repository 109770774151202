import { lazy } from 'react';
import { HomeOutlined, BlockOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { IRoute } from 'interfaces';

// App pages
const Home = lazy(() => import('containers/Home'));
const ProjectList = lazy(() => import('containers/ProjectList'));
// const MyProject = lazy(() => import('containers/MyProject'));
// const MyIssue = lazy(() => import('containers/MyIssue'));
// const NotificationSetup = lazy(() => import('containers/NotificationSetup'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 * You can change permissions to your IAM's permissions
 */
const routes: IRoute[] = [
  // This is a menu/route which has no children (sub-menu)
  {
    exact: true,
    path: '/',
    name: t('Home'),
    component: Home,
    icon: HomeOutlined,
    children: [
      // {
      //   exact: true,
      //   path: '/my-project',
      //   name: t('MyProject'),
      //   component: MyProject,
      // },
      // {
      //   exact: true,
      //   path: '/my-issue',
      //   name: t('MyIssue'),
      //   component: MyIssue,
      // },
      // {
      //   exact: true,
      //   path: '/noti-setup',
      //   name: t('NotificationSetup'),
      //   component: NotificationSetup,
      // },
      {
        exact: true,
        path: '/project-list',
        name: t('ProjectList'),
        component: ProjectList,
        icon: BlockOutlined,
      },
    ],
  },
];

export default routes;
