import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Spin } from 'antd';
import { IRoute } from 'interfaces';
import { StoreContext } from 'contexts';
import { userServices } from 'services';
import { projectHooks, userHooks } from 'hooks';
import { useListRelease } from 'hooks/release';
import { namespaceHelper } from 'helpers';
import { namespaceConstants } from 'constants/index';

const { getCurrentNamespace } = namespaceHelper;
const { NAMESPACE_LIST, DEFAULT_NAMESPACE } = namespaceConstants;

const PrivateRoute = ({
  component: Component,
  ...rest
}: Omit<IRoute, 'name'>) => {
  useEffect(() => {
    // Tracking - Set userId when logged in
    // track('setUserId', userServices.getUserInfo().sub);
  }, []);

  const {
    projects,
    fetchProjects,
    loading: loading1,
  } = projectHooks.useProject();
  const { onStagings, listReleased, loading: loading2 } = useListRelease();

  // Check if user is logged in or not
  if (!userServices.isLoggedIn()) {
    userServices.login();
    return null;
  }

  const { currentUser } = userHooks.useUserInfo();

  // Show spin when fetching required global data
  if (!currentUser) {
    return <Spin className="app-spin" />;
  }
  // Fetch global data

  const currentNamespace =
    NAMESPACE_LIST.find(
      namespace => namespace.code === getCurrentNamespace()
    ) || DEFAULT_NAMESPACE;

  // Show spin when fetching required global data
  if (!currentUser || loading1 || loading2) {
    return <Spin className="app-spin" />;
  }

  return (
    <StoreContext.Provider
      value={{
        currentUser,
        projects,
        releases: { onStagings, listReleased },
        fetchProjects,
        namespace: currentNamespace,
      }}
    >
      <Route
        {...rest}
        render={routeProps =>
          Component ? <Component {...routeProps} /> : null
        }
      />
    </StoreContext.Provider>
  );
};

export default PrivateRoute;
