import { Button, notification, Popconfirm } from 'antd';
import { versionHooks } from 'hooks';
import { t } from 'helpers/i18n';
import { DeleteOutlined } from '@ant-design/icons';

export interface IDeleteButtonProp {
  docVersion: string;
  projectCode: string;
  onSuccess?: () => void;
  onError?: (e: any) => {};
  versionId: number;
}

const DeleteButton = ({
  docVersion,
  projectCode,
  versionId,
  onSuccess,
  onError,
}: IDeleteButtonProp) => {
  const { loading, deleteVersion } = versionHooks.useDeleteVersion();

  const handleDeleteVersion = async () => {
    try {
      await deleteVersion(versionId);
      await onSuccess?.();
      notification.success({ message: t('DeleteSuccess') });
    } catch (e) {
      console.error(e);
      await onError?.(e);
    }
  };

  return (
    <div>
      <Popconfirm
        title={t('AreYouSureDeleteVersion')}
        onConfirm={handleDeleteVersion}
        okText={t('Yes')}
        cancelText={t('No')}
      >
        <Button
          type="primary"
          danger
          title={t('DeleteVersion')}
          icon={<DeleteOutlined />}
          loading={loading}
        />
      </Popconfirm>
    </div>
  );
};

export default DeleteButton;
