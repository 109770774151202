import { IRoute, IProject, INamespace } from 'interfaces';
import { stringHelper } from 'helpers';

const { mapProjectNameToPath } = stringHelper;

const buildRouteTree = (
  routes: IProject[],
  namespace: INamespace,
  component?: React.ElementType
): IRoute[] => {
  const result: IRoute[] = [];
  routes.forEach(route => {
    const paths = route.path.replace('/project/', '').split('/');
    let visitor = result;
    // Iterate through pa
    for (let i = 0; i < paths.length; i++) {
      const child = visitor.find(v => v.name === paths[i]);
      if (child) {
        visitor = child.children || [];
      } else {
        let tempChildren: IRoute[] = [];
        const tempRoute: IRoute = {
          name: paths[i],
          title: paths[i],
          children: tempChildren,
          path: `/project/${namespace.urlPath}/${mapProjectNameToPath(
            paths.slice(0, i + 1).join('/')
          )}`,
          key: `/project/${namespace.urlPath}/${mapProjectNameToPath(
            paths.slice(0, i + 1).join('/')
          )}`,
        };
        if (i === paths.length - 1) {
          if (component) {
            tempRoute.component = component;
          }
          tempRoute.id = route.id;
        }
        visitor.push(tempRoute);
        visitor = tempChildren;
      }
    }
  });
  return result;
};

const flattenRouteTree = (routes: IRoute[]): IRoute[] => {
  const result: IRoute[] = [];
  let stack: IRoute[] = [...routes];

  while (stack.length > 0) {
    // @ts-ignore: Never undefined cause we have check length above
    const temp: IRoute = stack.pop();
    if (temp.children) {
      stack = [...stack, ...temp.children];
    }
    result.push(temp);
  }
  return result;
};

const accumulatePath = (path: string): string[] => {
  return path
    .split('/')
    .reduce((acc: string[], curr, index, arr) => {
      const newAcc = [...acc, arr.slice(0, index + 1).join('/')];

      return newAcc;
    }, [])
    .filter(key => !!key);
};

export default {
  buildRouteTree,
  flattenRouteTree,
  accumulatePath,
};
