import { Form, Input, Modal, notification } from 'antd';
import { t } from 'helpers/i18n';
import { IProject } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { projectHooks } from 'hooks';

export interface IUpdateProjectPathModalProp {
  project?: IProject;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const UpdateProjectPathModal = ({
  project,
  visible,
  setVisible,
}: IUpdateProjectPathModalProp) => {
  const [projectPath, setProjectPath] = useState<string>('');
  const { loading, updateProjectPath } = projectHooks.useUpdateProjectPath();

  useEffect(() => {
    setProjectPath(project?.path || '');
  }, [project]);

  const updatePath = async () => {
    try {
      await updateProjectPath({
        projectId: Number(project?.id),
        newProjectPath: projectPath,
      });
      notification.success({ message: t('UpdateSuccess') });
      setVisible(false);
    } catch (e) {
      console.error(e);
      notification.error({ message: t('SomethingWentWrong') });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      okButtonProps={{ loading }}
      onOk={updatePath}
      title={project?.path}
      destroyOnClose
    >
      <Form.Item label={t('ProjectPath')}>
        <Input
          value={projectPath}
          onChange={e => {
            setProjectPath(e.target.value);
          }}
        ></Input>
      </Form.Item>
    </Modal>
  );
};

export default UpdateProjectPathModal;
