import { INamespace, ENamespace } from 'interfaces';
import commonConstants from './common';
const { ENV_MOD } = commonConstants;

const DEFAULT_NAMESPACE: INamespace =
  window.ENV_MODE === ENV_MOD.TEST1
    ? { name: 'Draft', code: ENamespace.draft, urlPath: 'draft' }
    : { name: 'Approved', code: ENamespace.approved, urlPath: 'approved' };

const NAMESPACE_LIST: INamespace[] = [
  { name: 'Draft', code: ENamespace.draft, urlPath: 'draft' },
  { name: 'Approved', code: ENamespace.approved, urlPath: 'approved' },
];

export default { ENamespace, DEFAULT_NAMESPACE, NAMESPACE_LIST };
