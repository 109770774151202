import { Dropdown, Menu } from 'antd';
import { useContext } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { namespaceConstants } from 'constants/index';
import NamespaceItem from './NamespaceItem';
import { StoreContext } from 'contexts';

const { NAMESPACE_LIST } = namespaceConstants;

const NamespaceSwitch = () => {
  const { namespace } = useContext(StoreContext);
  const namespaceMenu = (
    <Menu>
      {NAMESPACE_LIST.map(namespace => (
        <Menu.Item key={namespace.code}>
          <NamespaceItem
            code={namespace.code}
            name={namespace.name}
            urlPath={namespace.urlPath}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={namespaceMenu} trigger={['click']} className="mr-base">
      <span className="app-user">
        <strong>Namespace:</strong>
        <span className="label">{namespace.name}</span>
        <DownOutlined style={{ fontSize: 10 }} />
      </span>
    </Dropdown>
  );
};

export default NamespaceSwitch;
