import { useState, useMemo, useEffect } from 'react';
import { versionServices } from 'services';
import {
  EGenerateProtoBufMode,
  IFolderTree,
  IGenerateProtobufParams,
  IGetFileContentByCodeParams,
  IProtobufFile,
  ISearchVersionParams,
  IVersionLabel,
} from 'interfaces';

interface IDeployEnvironmentDockerImage {
  docVersion: string;
  projectCode: string;
  deployEnvironment: string;
}

interface IDeleteVersions {
  projectCode: string;
}

const useDeployVersion = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const deployVersion = async ({
    docVersion,
    projectCode,
    deployEnvironment,
  }: IDeployEnvironmentDockerImage) => {
    try {
      setLoading(true);
      await versionServices.deployVersion({
        version: docVersion,
        projectCode: projectCode,
        deployEnv: deployEnvironment,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    deployVersion,
    loading,
  };
};

const useDeleteVersion = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const deleteVersion = async (id: number) => {
    try {
      setLoading(true);
      await versionServices.deleteVersionById({
        id,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteVersion,
    loading,
  };
};

const useDeleteAllVersion = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const deleteAllVersion = async ({ projectCode }: IDeleteVersions) => {
    try {
      setLoading(true);
      await versionServices.deleteAllVersion({
        projectCode: projectCode,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteAllVersion,
    loading,
  };
};

const useGenerateProtobuf = () => {
  const [protobufFiles, setProtobufFiles] = useState<IProtobufFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedFilePath, setSelectedFilePath] = useState<string>('');

  const saveProtobufFile = async (params: IGenerateProtobufParams) => {
    try {
      setSaveLoading(true);
      await versionServices.generateProtobuf({
        ...params,
        mode: EGenerateProtoBufMode.SAVE,
      });
    } finally {
      setSaveLoading(false);
    }
  };
  const fetchProtobufFile = async (params: IGenerateProtobufParams) => {
    try {
      setLoading(true);
      const res = await versionServices.generateProtobuf({
        ...params,
        mode: EGenerateProtoBufMode.PREVIEW,
      });
      const protobufFileData = res.data.files;
      setProtobufFiles(protobufFileData);
      if (protobufFileData.length > 0) {
        setSelectedFilePath(protobufFileData[0].filePath);
      }
    } finally {
      setLoading(false);
    }
  };

  const folderTree = useMemo(() => {
    const result: IFolderTree[] = [];
    protobufFiles.forEach(protobufFile => {
      const paths = protobufFile.filePath.split('/');
      let visitor = result;
      for (let i = 0; i < paths.length; i++) {
        const child = visitor.find(v => v.title === paths[i]);
        if (child) {
          visitor = child.children || [];
        } else {
          let tempChildren: IFolderTree[] = [];
          const tempFolder: IFolderTree = {
            title: paths[i],
            children: tempChildren,
            key: paths.slice(0, i + 1).join('/'),
          };
          if (i === paths.length - 1) {
            tempFolder.fileContent = protobufFile.fileContent;
          }
          visitor.push(tempFolder);
          visitor = tempChildren;
        }
      }
    });
    return result;
  }, [protobufFiles]);

  const fileContent: string = useMemo(() => {
    const protobuf = protobufFiles.find(
      protobufFile => protobufFile.filePath === selectedFilePath
    );
    return protobuf?.fileContent ?? '';
  }, [selectedFilePath]);

  return {
    protobufFiles,
    folderTree,
    fetchProtobufFile,
    loading,
    saveLoading,
    selectedFilePath,
    setSelectedFilePath,
    fileContent,
    saveProtobufFile,
  };
};

const useSearchVersion = (defaultParams: ISearchVersionParams) => {
  const [versionLabels, setVersionLabels] = useState<IVersionLabel[]>([]);
  const [params, setParams] = useState<ISearchVersionParams>(defaultParams);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const fetchVersionLabel = async () => {
    try {
      setLoading(true);
      const res = await versionServices.getVersionLabel(params);
      setVersionLabels(res.data.versions);
      setCurrentPage(1);
      setCanLoadMore(true);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    if (canLoadMore) {
      try {
        setLoading(true);
        const res = await versionServices.getVersionLabel({
          ...params,
          page: currentPage + 1,
        });
        setVersionLabels([...versionLabels, ...res.data.versions]);
        setCurrentPage(currentPage + 1);
        if (res.data.versions.length < (params.pageSize ?? 10)) {
          setCanLoadMore(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchVersionLabel();
  }, [params]);

  return {
    setParams,
    params,
    versionLabels,
    loadMore,
    loading,
  };
};

const useApiDocFileContentByCode = (
  defaultParams: IGetFileContentByCodeParams
) => {
  const [fileContent, setFileContent] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(defaultParams);

  const fetchApiFileContent = async () => {
    try {
      setLoading(true);
      const res = await versionServices.getFileContentByCode(params);
      setFileContent(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiFileContent();
  }, [params]);
  return {
    fileContent,
    loading,
    params,
    setParams,
  };
};

export default {
  useDeployVersion,
  useDeleteVersion,
  useDeleteAllVersion,
  useGenerateProtobuf,
  useSearchVersion,
  useApiDocFileContentByCode,
};
