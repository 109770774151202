import { namespaceHelper } from 'helpers';

const { changeCurrentNamespace } = namespaceHelper;

interface INamespaceItemProp {
  name: string;
  code: string;
  urlPath: string;
}

const NamespaceItem = ({ name, code, urlPath }: INamespaceItemProp) => {
  return (
    <div
      onClick={() => {
        changeCurrentNamespace(code);
        window.location.assign(
          window.location.pathname.replace(/approved|draft/gm, urlPath)
        );
      }}
    >
      <span className="ml-base">{name}</span>
    </div>
  );
};

export default NamespaceItem;
