const IAM_SCOPES = [
  'openid',
  'profile',
  'read:permissions',
  // You can add more scopes (granted services) here
  'api-doc-be-api',
  'dgl:api-doc-be-api',
];

const BASE_GITLAB_COMMIT_URL =
  'https://git.teko.vn/digilife/tools/api-doc/-/commit/';

const BASE_GITLAB_MR_URL =
  'https://git.teko.vn/digilife/tools/api-doc/-/merge_requests/';

const BASE_JIRA_ISSUE_URL = 'https://jira.teko.vn/browse/';

const PATH = {
  project: {
    doc: '/project-doc/:namespace/:projectPath/version/:versionTag',
  },
};

const ENV_MOD = {
  TEST1: 'test1',
  DEV: 'dev',
  STAG: 'stag',
};

export default {
  IAM_SCOPES,
  ALL: 'all',
  TABLET_WIDTH: 768,
  BASE_GITLAB_COMMIT_URL,
  BASE_GITLAB_MR_URL,
  BASE_JIRA_ISSUE_URL,
  PATH,
  ENV_MOD,
};
