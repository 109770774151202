export enum EReleaseStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITING_REVIEW = 'Waiting review',
}

export interface IReleases {
  onStagings: string[];
  listReleased: string[];
}

export interface IJiraTicket {
  ticketName: string;
  ticketUrl: string;
  epicName?: string;
  epicUrl?: string;
  mrUrls?: string[];
  validateMessages?: string[];
  deployValidated?: boolean;
  labels?: string[];
  featureFlags?: string[];
  isApproved?: boolean;
  affectedServices?: string[];
  isOwner?: boolean;
}

export interface IReleaseVersion {
  id: number;
  version: string;
  versionUrl: string;
  projectName: string;
  serviceName: string;
  createdAt?: string;
  releasedAt?: string;
  createdBy?: string;
  related: string[];
  enableCheck: string[];
  waitApprove: string[];
  approved: { [key: string]: string[] };
  rejected: { [key: string]: string[] };
}
