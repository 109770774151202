import { Spin } from 'antd';
import { StoreContext } from 'contexts';
import React, { useContext } from 'react';

const ensureProjectsLoaded = (Component: any) => ({ ...props }) => (
  <ProjectsLoadedRequirer>
    <Component></Component>
  </ProjectsLoadedRequirer>
);

const ProjectsLoadedRequirer = ({ children }: any) => {
  const { projects } = useContext(StoreContext);
  return <>{projects.length > 0 ? children : <Spin className="app-spin" />}</>;
};

export default ensureProjectsLoaded;
