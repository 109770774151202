import { createContext } from 'react';
import { IUserDetailsInfo } from 'teko-oauth2';
import { currentUser } from 'services/mocks/user';
import { INamespace, IProject, IReleases } from 'interfaces';
import { namespaceConstants } from 'constants/index';

const { DEFAULT_NAMESPACE } = namespaceConstants;

interface StoreContextType {
  currentUser: IUserDetailsInfo;
  projects: IProject[];
  releases: IReleases;
  fetchProjects: Function;
  namespace: INamespace;
}

export const StoreContext = createContext<StoreContextType>({
  currentUser,
  projects: [],
  releases: { onStagings: [], listReleased: [] },
  fetchProjects: () => {},
  namespace: DEFAULT_NAMESPACE,
});
