import { requestServices } from 'services';

const { workflowManagerClient } = requestServices;

interface WorkflowManagerResponse {
  code: string;
  message?: string;
}

export async function getReleases(): Promise<
  WorkflowManagerResponse & {
    on_stagings: string[];
    list_released: string[];
  }
> {
  const response = await workflowManagerClient.get('/v1/releases/list');
  return response.data;
}

export interface ITicket {
  id: number;
  jira_link: string;
  jira_code: string;
  jira_title: string;
  jira_status?: string;
  labels?: string[];
  feature_flags?: string[];
  deploy_validate_status: string;
  deploy_validate_messages?: string[];
  mr_links: string[];
  epic_code?: string;
  epic_link?: string;
  epic_title?: string;
  is_approved?: boolean;
  affected_services?: string[];
  is_owner?: boolean;
}

export interface IDeployment {
  id: number;
  name: string;
  service: string;
  version: string;
  link: string;
  created_at?: string;
  released_at?: string;
  created_by?: string;
  related: string[];
  enable_check: string[];
  wait_approve: string[];
  approved: { [key: string]: string[] };
  rejected: { [key: string]: string[] };
}

export interface IDeploymentDetail extends IDeployment {
  is_owner: boolean;
  can_approve: boolean;
  tickets: ITicket[];
  affected_services: string[];
}

export interface IRelease {
  fix_version: string;
  link_jira_fix_version: string;
  from_date: string;
  to_date: string;
  deployments: IDeployment[];
}

export interface IGetRelease {
  is_released: boolean;
  is_owner: boolean;
}

export async function getRelease(
  fixVersion: string,
  params: IGetRelease
): Promise<WorkflowManagerResponse & { release: IRelease }> {
  const response = await workflowManagerClient.get(
    `/v1/releases/${fixVersion}`,
    { params }
  );
  return response.data;
}

export async function getDeploymentDetail(
  deployment_id: number
): Promise<WorkflowManagerResponse & { detail: IDeploymentDetail }> {
  const response = await workflowManagerClient.get(
    `/v1/releases/deployments/${deployment_id}`
  );
  return response.data;
}

export interface IMissingRelease {
  tickets: ITicket[];
}

export async function getMissingRelease(
  fixVersion: string,
  is_released: boolean
): Promise<WorkflowManagerResponse & { release: IMissingRelease }> {
  const response = await workflowManagerClient.get(
    `/v1/releases/${fixVersion}/missing?is_released=${is_released}`
  );
  return response.data;
}

const mapAction = {
  approve: 1,
  reject: 2,
};

export async function reviewDeployment(
  deploymentId: number,
  action: keyof typeof mapAction
): Promise<any> {
  const resp = await workflowManagerClient.patch(
    `/v1/releases/deployments/${deploymentId}`,
    {
      is_pass: mapAction[action],
    }
  );
  return resp.data;
}
