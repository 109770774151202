import { requestServices } from 'services';
import {
  IDeleteVersionParams,
  IDeployEnvironmentImageParams,
  IDeployImageResponse,
  IDeleteAllVersion,
  IGenerateProtobufParams,
  IGenerateProtobufResponse,
  ISearchVersionResponse,
  IApiResponse,
  IGetFileContentByCodeParams,
  ISearchVersionParams,
  IGetFileContentByIdParams,
  IDeleteVersionByIdParams,
} from 'interfaces';
import { requestHelpers } from 'helpers';

const { docBuilderClient } = requestServices;

const deployVersion = (
  params: IDeployEnvironmentImageParams
): Promise<IApiResponse<IDeployImageResponse>> => {
  const body = { deploy_env: params.deployEnv };
  return docBuilderClient
    .post(
      `/project/${params.projectCode}/version/${params.version}/deploy`,
      body
    )
    .then(requestHelpers.getResult);
};

const deployAllVersion = (params: IDeleteVersionParams): Promise<{}> => {
  return docBuilderClient
    .delete(`/project/${params.projectCode}/version/${params.version}`)
    .then(requestHelpers.getResult);
};

const deleteVersionById = (params: IDeleteVersionByIdParams) => {
  return docBuilderClient
    .delete(`/version/${params.id}`)
    .then(requestHelpers.getResult);
};

const deleteAllVersion = (params: IDeleteAllVersion): Promise<{}> => {
  return docBuilderClient
    .delete(`/project/${params.projectCode}/version`)
    .then(requestHelpers.getResult);
};

const generateProtobuf = (
  params: IGenerateProtobufParams
): Promise<IApiResponse<IGenerateProtobufResponse>> => {
  return docBuilderClient
    .post(`/version/generateProtobuf`, params)
    .then(requestHelpers.getResult);
};

const getVersionLabel = (
  params: ISearchVersionParams
): Promise<IApiResponse<ISearchVersionResponse>> => {
  return docBuilderClient
    .get(`/version/searchByProject`, { params })
    .then(requestHelpers.getResult);
};

const getFileContentByCode = (
  params: IGetFileContentByCodeParams
): Promise<string> => {
  return docBuilderClient
    .get('/version/getFileContentByCode', { params })
    .then(requestHelpers.getResult);
};

const getFileContentById = (
  params: IGetFileContentByIdParams
): Promise<string> => {
  return docBuilderClient
    .get('/version/getFileContentById', { params })
    .then(requestHelpers.getResult);
};

export default {
  deployVersion,
  deleteAllVersion,
  deployAllVersion,
  generateProtobuf,
  getVersionLabel,
  getFileContentByCode,
  getFileContentById,
  deleteVersionById,
};
